<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="desc">
      <scroll ref="scroll" class="scroll" :data="data">
        <div>
          <div class="title">
            用户注册协议
          </div>
          <div class="content-block">
            <div class="content">
              一、总则
            </div>
            <div class="content">
              1.内蒙古自治区图书馆网站及微信平台可允许用户在线注册或线下注册或办理读者证，本协议针对在线注册的内蒙古图书馆电子读者证。
            </div>
            <div class="content">
              2.用户在注册之前，应当仔细阅读本协议，并同意遵守本协议后方可成为注册用户。一旦注册成功，则用户与内蒙古自治区图书馆网站及微信平台之间自动形成协议关系，用户应当受本协议的约束。用户在使用特殊的服务或时，应当同意接受相关协议后方能使用。
            </div>
            <div class="content">
              3.本协议可由内蒙古自治区图书馆网站及微信平台随时更新，用户应当及时关注并同意，本站不承担通知义务。本站的通知、公告、声明或其它类似内容是本协议的一部分。
            </div>
            <div class="content">
              二、服务内容
            </div>
            <div class="content">
              用户登录后可阅读本网站及微信平台所有向用户公开的内容，可发表评论，浏览网站及微信平台向用户公开的资源，报名参加相关会议与活动，暂不允许发布日志等内容。
            </div>
            <div class="content">
              三、用户帐号
            </div>
            <div class="content">
              1.经本站注册系统完成注册程序并通过的用户即成为注册读者，享有用户所享有的权限，内蒙古自治区图书馆有权对会员的权限设计进行变更。
            </div>
            <div class="content">
              2.用户有义务保证密码和帐号的安全，用户利用该密码和帐号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本站不承担任何责任。如用户发现帐号遭到未授权的使用或发生其他任何安全问题，应立即修改帐号密码并妥善保管，如有必要，请通知本站。因黑客行为或用户的保管疏忽导致帐号非法使用，本站不承担任何责任。
            </div>
            <div class="content">
              四、使用规则
            </div>
            <div class="content">
              遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《最高人民法院关于审理涉及计算机网络著作权纠纷案件适用法律若干问题的解释(法释[2004]1号)》、《全国人大常委会关于维护互联网安全的决定》、《互联网电子公告服务管理规定》、《互联网新闻信息服务管理规定》、《互联网著作权行政保护办法》和《信息网络传播权保护条例》等有关计算机互联网规定和知识产权的法律和法规、实施办法。
            </div>
            <div class="content">
              五、隐私保护
            </div>
            <div class="content">
              1.本站不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在本站的非公开内容，但下列情况除外：
            </div>
            <div class="content">
              (1)事先获得用户的明确授权；
            </div>
            <div class="content">
              (2)根据有关的法律法规要求；
            </div>
            <div class="content">
              (3)按照相关政府主管部门的要求；
            </div>
            <div class="content">
              2.在不透露单个用户隐私资料的前提下，本站有权对整个用户数据库进行分析并对用户数据库进行非商业性的利用。
            </div>
            <div class="content">
              六、版权声明
            </div>
            <div class="content">
              1.本站的文字、文档、图片、音频、视频等版权均归内蒙古自治区图书馆享有或与作者共同享有。
            </div>
            <div class="content">
              2.本站特有的标识、版面设计、编排方式等版权均属内蒙古自治区图书馆享有，未经本站许可，不得任意复制或转载。
            </div>
            <div class="content">
              3.使用本站的任何内容均应注明“来源于内蒙古自治区图书馆网站及微信平台”及署上作者姓名，按法律规定需要支付稿酬的，应当通知本站及作者及支付稿酬，并独立承担一切法律责任。
            </div>
            <div class="content">
              4.本站享有所有作品用于其它用途的优先权，包括但不限于网站及微信平台、电子杂志、平面出版等，但在使用前会通知作者，并按同行业的标准支付稿酬。
            </div>
            <div class="content">
              5.本站所有内容仅代表作者自己的立场和观点，与本站无关，由作者本人承担一切法律责任。
            </div>
            <div class="content">
              6.恶意转载本站内容的，本站保留将其诉诸法律的权利。
            </div>
            <div class="content">
              七、附则
            </div>
            <div class="content">
              本协议解释权及修订权归内蒙古自治区图书馆所有。
            </div>
          </div>
        </div>
      </scroll>
    </div>
  </transition>
</template>

<script>
import Scroll from '@/components/scroll/scroll'

export default {
  name: 'UserAgreement',
  components: {
    Scroll
  },
  data () {
    return {
      data: []
    }
  }
}
</script>

<style scoped lang="stylus">
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.desc
  position absolute
  z-index 100
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)
  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    overflow hidden
    background rgba(255, 255, 255, 1)
    .title
      width 750px
      font-size 32px
      line-height 40px
      text-align center

    .content-block
      width 690px
      padding 30px

      .content
        width 690px
        text-indent 48px
        line-height 40px
        font-size 24px
</style>
